import React  from 'react';
import './css/Styles.css'
import Profile from "./assets/img/profile.jpg";
import ResumeFile from './resume.pdf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faGit } from '@fortawesome/free-brands-svg-icons';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';

const Header = () =>{
    return(
        <head>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta name="description" content="" />
        <meta name="author" content="" />
        <title>Resume</title>
        <link rel="icon" type="image/x-icon" href="assets/img/favicon.ico" />
        <script src="https://use.fontawesome.com/releases/v6.3.0/js/all.js" crossOrigin="anonymous"></script>
        <script src="https://kit.fontawesome.com/eb674119d6.js" crossOrigin="anonymous"></script>
        <link href="https://fonts.googleapis.com/css?family=Saira+Extra+Condensed:500,700" rel="stylesheet" type="text/css" />
        <link href="https://fonts.googleapis.com/css?family=Muli:400,400i,800,800i" rel="stylesheet" type="text/css" />
        </head>
    );
}

const Navigation = () => {
    return(
       
             <nav class="navbar navbar-expand-lg navbar-dark bg-primary fixed-top" id="sideNav">
            <a class="navbar-brand js-scroll-trigger" href="#page-top">
                <span class="d-block d-lg-none">Doug Dexter</span>
                <span class="d-none d-lg-block">
                    <img class="img-fluid img-profile rounded-circle mx-auto mb-2" src={Profile} alt="D&G" />
                </span>
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
            <div class="collapse navbar-collapse" id="navbarResponsive">
                <ul class="navbar-nav">
                    <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#about">About</a></li>
                    <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#experience">Experience</a></li>
                    <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#education">Education</a></li>
                    <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#skills">Skills</a></li>
                    <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#samples">Samples</a></li>
                    <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#interests">Interests</a></li>
                    <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#resume">PDF Resume</a></li>
                </ul>
            </div>
        </nav>
    );
}


const About = () => {
    return(
            <section class="resume-section" id="about">
                <div class="resume-section-content">
                    <h1 class="mb-0">
                        Doug
                        <span class="text-primary">Dexter</span>
                    </h1>
                    {/* <div class="subheading mb-5">
                        <a href="mailto:drdexter33@protonmail.com"> email</a>
                    </div> */}
                    <p class="lead mb-5">Highly effective systems developer/programmer with over 20 years of experience as an ASP.Net/C# application 
                        developer and tier III support technician. 
                        <hr />
                        Highly experienced in troubleshooting technical issues and identifying root causes in complex
                        enterprise applications with career expertise in leading agile delivery and sprint planning meetings.
                        <hr />
                        Collaborative professional skilled in providing support and custom development for enterprise support applications. 
                    </p>
                    <div class="social-icons">
                        <a class="social-icon" href="https://www.linkedin.com/in/douglas-dexter-a798588/">
                             {/* <i class={faLinkedinIn}></i> */}
                             <FontAwesomeIcon icon={faLinkedinIn}></FontAwesomeIcon>
                        </a>
                        <a class="social-icon" href="https://github.com/drdexter33">
                           <FontAwesomeIcon icon={faGit}></FontAwesomeIcon>
                        </a>
                        <a class="social-icon" href="https://www.youtube.com/channel/UCTbVu9r1RVHNzjTX_BDZfFQ">
                           <FontAwesomeIcon icon={faYoutube}></FontAwesomeIcon>
                        </a>
                        <a class="social-icon" href="mailto:drdexter33@protonmail.com">
                           <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>
                        </a>
                        

                    </div>
                </div>
            </section>
    );
}

const Experience = () => {
    return (
        <section class="resume-section" id="experience">
                <div class="resume-section-content">
                    <h2 class="mb-5">Experience</h2>
                    <div class="d-flex flex-column flex-md-row justify-content-between mb-5">
                        <div class="flex-grow-1">
                            <h3 class="mb-0">Senior Systems Developer/Application Manager</h3>
                            <div class="subheading mb-3">PPD/Thermo Fisher Scientific</div>
                            <p>Worked as Application Manager/Vendor Manager, Tier 3 Technical Support Analyst and Applications Developer for the PPD Clinical Systems Engineering team.</p>
                            <p>Skilled in coding, database analysis, vendor management, debugging, troubleshooting, and investigating bugs and technical issues in complex software systems.</p>
                            <p>As Application Manager managed multiple offshore vendor teams for support and development streams, Agile sprints, DevOps backlog items, documentation reviews, vendor accounts, and Azure Cloud infrastructure and resources.</p>
                        </div>
                        <div class="flex-shrink-0"><span class="text-primary">July 2012 - July 2023</span></div>
                    </div>
                </div>    
        </section>  
    );
}


const Education = () => {
    return(
        <section class="resume-section" id="education">
        <div class="resume-section-content">
            <h2 class="mb-5">Education</h2>
            <div class="d-flex flex-column flex-md-row justify-content-between mb-5">
                <div class="flex-grow-1">
                    <h3 class="mb-0">University of North Carolina at Greensboro</h3>
                    <div class="subheading mb-3">Bachelor of Science</div>
                    <div>Information Systems Operations Management</div>
                </div>
                <div class="flex-shrink-0"><span class="text-primary">May 2000</span></div>
            </div>
        </div>
    </section>  
    );
}

const Skills = () => {
    return (
        <section class="resume-section" id="skills">
        <div class="container">
            <div class="row">
                <h2 class="mb-5">Skills</h2>
                    <div class="col-sm">
                <div class="subheading mb-3">Programming Languages & Tools</div>
                        <ul>
                            <li>ASP.NET</li>
                            <li>ASP.NET Core</li> 
                            <li>ASP.NET MVC </li>
                            <li>ASP.NET Webforms </li>
                            <li>Azure Logic Apps </li>
                            <li>Azure Function Apps </li>
                            <li>C#</li>
                            <li>Classic ASP </li>
                            <li>HTML</li>
                            <li>JavaScript </li>
                            <li>Java</li>
                            <li>PHP</li>
                            <li>React</li>
                            <li>VB.NET </li>
                            <li>VBScript </li>
                            <li>VBA</li>
                            <li class="last">Web API </li>
                        </ul>
                    </div>
                    
                    <div class="col-sm">
                        <div class="subheading mb-3">Databases</div>
                            <ul>
                                <li>Microsoft SQL Server</li>
                                <li>Oracle</li>
                                <li>SQL Server Reporting Services</li>
                                <li>SQL Server Integration Services</li>
                                <li class="last">Quest TOAD</li>
                            </ul>
                        <div class="subheading mb-3">Cloud</div>
                            <ul>
                                <li> Azure Portal</li>
                                <li class="last">Azure DevOps</li>
                            </ul>
                        <div class="subheading mb-3">Tools</div>
                            <ul>
                                <li>Visual Studio</li>
                                <li class="last">Visual Studio Code</li>
                            </ul>
                    </div>
                    <div class="col-sm">
                        <div class="subheading mb-3">Data Integrations</div>
                        <ul>
                            <li class="last">Snowflake EDW</li>
                        </ul>
                        <div class="subheading mb-3">Other</div>
                        <ul>
                            <li>Adobe Suite</li>
                            <li>Checkmarx</li>
                            <li>Crystal Reports</li>
                            <li>Infragistics Controls</li>
                            <li>Internet Information Services</li>
                            <li>Powershell</li>
                            <li>Qualys</li>
                            <li>Telerik Controls</li>
                            <li>VSTO</li>
                            <li>Web Services</li>
                        </ul>
                    </div>
                </div>
        </div>
        </section>
    );
}

const reactLink = "https://wonderful-cliff-0c2a01f0f.5.azurestaticapps.net/";
const reactLink2 = "https://lemon-glacier-0c9156c0f.5.azurestaticapps.net/";

const Samples = () => {
return(
<section class="resume-section" id="samples">
                <div class="resume-section-content">
                    <h2 class="mb-5">React Samples</h2>
                    <p><a href={reactLink} target="_blank" rel="noopener noreferrer">React AG-Grid Sample (API) </a></p>
                    <p><a href={reactLink2} target="_blank" rel="noopener noreferrer">React AG-Grid CRUD Sample (Local Storage) </a></p>
                </div>
            </section>

);



}


const Interests = () => {
    return (
            <section class="resume-section" id="interests">
                <div class="resume-section-content">
                    <h2 class="mb-5">Interests</h2>
                    <p>Most of my free time I spend reading, at the beach with my dog or playing and learning more about music</p>
                </div>
            </section>
    );
}

const Resume = () => {
    return (

        <section class="resume-section" id="resume">
                <div class="resume-section-content">
                    <h2 class="mb-5">Resume</h2>
                    <p>
                    <a href={ResumeFile} >
                        Click to download...
                    </a>
                    </p>
                </div>
            </section>


        
    )
}

function App() {
    return(
        <>
            <Header />
             {/* <body id="page-top"> */}
                <Navigation />
                <div class="container-fluid p-0">
                    <About />
                    <Experience />
                    <Education />
                    <Skills />
                    <Samples />
                    <Interests />
                    <Resume />
                </div>
                    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js"></script>
                    <script src="js/scripts.js"></script>
                
            {/* </body> */}
        </>
    );
}

export default App;
